import dayjs from "dayjs";
import { useMatch, useNavigate, Link } from "@tanstack/react-location";
import { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Group,
  Title,
  Stepper,
  Text,
  Button,
  Loader,
  MediaQuery,
} from "@mantine/core";
// import { Calendar, DatePicker } from "@mantine/dates";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ArrowLeft, Clock, ChevronRight } from "react-feather";

import LoginLayout from "../layouts/LoginLayout";
import {
  useService,
  useServiceSlots,
  useServiceSlotsV2,
} from "../services/services";
import { AvilableDays } from "../types/service";
import { GetStringSlot } from "../utils/helpers";
import { BookAppointmentRequest } from "../types/appointment";
import {
  createAppointment,
  rescheduleAppointment,
  useAppointment,
} from "../services/appointment";

import { useAddress } from "../services/address";
import { GetProfile } from "../services/auth";
import { CT } from "../constants";

const Booking = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<number>();
  const [minDate, setMinDate] = useState<any>();
  const [maxDate, setMaxDate] = useState<any>();
  const [selectedSlotId, setSelectedSlotId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    params: { aid },
  } = useMatch();

  const { isLoading, status, data, error } = useAppointment(parseInt(aid));
  const [unavailableDays, setUnavailableDays] = useState<number[]>([]);
  const [slots, setSlots] = useState<number[]>([]);
  const [bookingError, setBookingError] = useState<string>("");
  const [addressId, setAddressId] = useState<number>();
  const [pincode, setPincode] = useState<number>();
  const [vendor, setVendor] = useState<string>();
  const [vendorCode, setVendorCode] = useState<string>();
  console.info(data);

  const {
    isLoading: addressLoading,
    data: addressData,
    error: addressError,
  } = useAddress();

  useEffect(() => {
    if (!isLoading) {
      setAddressId(data?.appointment?.addressId);
      setVendor(data?.appointment?.vendorId);
      setVendorCode(data?.appointment?.vendorCode);

      const address = addressData?.filter(
        (a) => a.id === data?.appointment?.addressId
      );

      if (address?.length) {
        setPincode(address[0].pincode);
      }

      let ad = data?.service?.availableDays.replaceAll("\\", "");
      let days: AvilableDays[] = JSON.parse(ad);
      let unavail: number[] = [];
      days?.map((d: AvilableDays, i: number) => {
        if (!d?.available) {
          unavail.push(i);
        }
      });
      setUnavailableDays(unavail);
      console.info(unavailableDays, days);
      const minDate = dayjs(new Date())
        .startOf("d")
        .add(data?.service?.minDayBooking as number, "d")
        .toDate();
      const maxDate = dayjs(new Date())
        .startOf("d")
        .add(data?.service?.minDayBooking as number, "d")
        .toDate();
      setMinDate(minDate);
      setMaxDate(maxDate);
    }
    const myButton = document.querySelector(
      ".react-calendar__navigation__next-button"
    );
    myButton?.setAttribute("id", "calendar_navigation_next_button");
  }, [data, addressData]);

  // const { data: slotData } = useServiceSlots(
  //   parseInt(data?.service?.ID),
  //   dayjs(selectedDate).format("YYYY-MM-DD"),
  //   {
  //     enabled: !!selectedDate,
  //   }
  // );
  const profile = GetProfile();

  const { data: slotData, isLoading: slotsLoading } = useServiceSlotsV2(
    parseInt(data?.service?.ID),
    dayjs(selectedDate).format("YYYY-MM-DD"),
    {
      enabled: !!(selectedDate !== null),
    },
    profile?.patientProfile?.id,
    pincode as number,
    vendor,
    vendorCode,
    addressId as number
  );

  const handleBooking = async () => {
    setLoading(true);
    let appointment = {
      appointmentID: parseInt(aid),
      appointmentDate: dayjs(selectedDate)
        .add(5, "hours")
        .add(30, "minutes")
        .toISOString(),
      timeSlot: selectedSlot,
      timeSlotId: selectedSlotId,
    };

    try {
      const ap = await rescheduleAppointment(appointment);

      navigate({
        to: "/rescheduled",
        replace: true,
      });
    } catch (error) {
      try {
        let err = JSON.parse(error?.response?.data);
        console.error(err);
        let errMsg = JSON.parse(err.message);
        setBookingError(errMsg?.message);
      } catch {
        setBookingError(error?.response?.data);
      }
    }
    console.info(appointment);
    setLoading(false);
  };

  console.info(slotData);

  const tileDisabled = (date) => {
    return unavailableDays.includes(date.date.getDay());
  };

  return (
    <LoginLayout>
      <Container size={"sm"} style={{ width: "100%" }}>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <Group
            align={"center"}
            py={40}
            style={{ margin: "0 auto", justifyContent: "center" }}
          >
            <ArrowLeft />
            <Text>Back to home</Text>
          </Group>
        </Link>
        <Title order={2} align="center" mb={"xl"}>
          Reschedule your appointment
        </Title>
      </Container>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box
            p={20}
            sx={(theme) => ({
              color: theme.shadows.sm,
              border: `1px solid ${theme.colors.gray[3]}`,
              borderRadius: theme.radius.md,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "60vh",
              [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                alignItems: "flex-start",
              },
            })}
          >
            <Grid
              align="center"
              justify={"space-between"}
              style={{ height: "100%", width: "100%" }}
            >
              <Grid.Col md={4} span={12}>
                <Box
                  sx={(theme) => ({
                    backgroundColor: theme.colors.indigo[0],
                    borderRadius: theme.radius.md,
                    padding: theme.spacing.md,
                    marginBottom: theme.spacing.md,
                  })}
                >
                  <Title order={4} mb="sm" id="old_appointment">
                    {" "}
                    Old appointment
                  </Title>
                  <Group spacing={"md"}>
                    <Text>
                      {dayjs(data?.appointment?.appointmentDate).format(
                        "DD MMM YYYY"
                      )}
                    </Text>
                    <Text>{GetStringSlot(data?.appointment?.timeSlot)}</Text>
                  </Group>
                </Box>
                <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                  <>
                    <Text size="lg" weight="bolder">
                      {data?.service?.name}
                    </Text>
                    <Group spacing="xs">
                      <Clock height={16} width={16}></Clock>
                      <Text>{data?.service?.duration} min</Text>
                    </Group>
                    <Text color="dark">{data?.service?.description}</Text>
                  </>
                </MediaQuery>
              </Grid.Col>
              <Grid.Col span={12} md={4}>
                <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                  <>
                    <div id="reschedule_appointment_calendar">
                      <Calendar
                        value={selectedDate}
                        style={{ width: "100%" }}
                        onChange={setSelectedDate}
                        maxDetail="month"
                        minDetail="year"
                        tileDisabled={(date) => tileDisabled(date)}
                        minDate={dayjs(new Date())
                          .add(data?.service?.minDayBooking as number, "days")
                          .toDate()}
                        maxDate={dayjs(new Date())
                          .add(data?.service?.maxDayBooking as number, "days")
                          .toDate()}
                      ></Calendar>
                    </div>
                    {/* <Calendar
                    value={selectedDate}
                    style={{ width: "100%" }}
                    onChange={setSelectedDate}
                    maxDetail="month"
                    minDetail="year"
                    tileDisabled={(date) => tileDisabled(date)}
                    minDate={dayjs(new Date())
                      .add(data?.service?.minDayBooking as number, "days")
                      .toDate()}
                    maxDate={dayjs(new Date())
                      .add(data?.service?.maxDayBooking as number, "days")
                      .toDate()}
                  ></Calendar> */}
                  </>

                  {/* <Calendar
                    value={selectedDate}
                    onChange={setSelectedDate}
                    weekendDays={[]}
                    excludeDate={(date) =>
                      unavailableDays.indexOf(date.getDay()) !== -1
                    }
                    minDate={minDate}
                    maxDate={maxDate}
                  ></Calendar> */}
                </MediaQuery>
              </Grid.Col>
              <Grid.Col md={4} span={12}>
                {selectedDate ? (
                  <>
                    <Text size="md" mb={20}>
                      {selectedDate
                        ? dayjs(selectedDate).format("dddd, MMM DD")
                        : ""}
                    </Text>
                    <div style={{ height: "320px", overflowY: "scroll" }}>
                      {slotsLoading && <Loader />}
                      {slotData?.slots !== null &&
                        slotData?.slots.map((a) => (
                          <Slot
                            key={a.key}
                            time={GetStringSlot(a.value)}
                            selected={selectedSlot == a.value}
                            onClick={() => {
                              setSelectedSlot(a.value);
                              setSelectedSlotId(a.key);
                            }}
                          />
                        ))}
                      {!slotsLoading &&
                        (slotData?.slots == null ||
                          slotData?.slots.length == 0) && (
                          <Text color={"dimmed"}>
                            No slots found for this day.
                          </Text>
                        )}
                    </div>
                    {bookingError !== "" && (
                      <Box
                        py={8}
                        my={12}
                        sx={(theme) => ({
                          backgroundColor: theme.colors.red[6],
                          borderRadius: theme.radius.sm,
                        })}
                      >
                        <Text align="center" color={"white"}>
                          {bookingError}
                        </Text>
                      </Box>
                    )}
                    <Button
                      id="confirm_reschedule"
                      fullWidth={true}
                      radius="sm"
                      size="lg"
                      disabled={!!!selectedSlot || loading}
                      onClick={() => handleBooking()}
                    >
                      {loading && (
                        <Loader size={"sm"} mr="md" color={"white"} />
                      )}
                      <Group spacing="sm">
                        <Text>Confirm booking</Text>
                        <ChevronRight width={18} />
                      </Group>
                    </Button>
                  </>
                ) : (
                  <Title order={4}>Select a date to view time slots</Title>
                )}
              </Grid.Col>
            </Grid>
          </Box>
        </>
      )}
    </LoginLayout>
  );
};

const Slot = ({
  time = "10:00 am",
  selected = false,
  onClick,
}: {
  time?: string;
  selected?: boolean;
  onClick?: () => any;
}) => (
  <Button
    id={time}
    color="indigo"
    variant={selected ? "filled" : "outline"}
    fullWidth={true}
    my={8}
    onClick={onClick}
  >
    {time}
  </Button>
);

export default Booking;
